import React from 'react'

const VideoDetail = ({ video }) => {
    if (!video) {
        return <div>Loading...</div>
    }

    const videoSrc = process.env.REACT_APP_YOUTUBE_VIDEO_SRC_URL + video.id.videoId

    return (
        <div>
            <div className="ui embed">
                <iframe
                    src={videoSrc}
                    title="video player"
                />
            </div>
            <div className="ui segment">
                <h4 className="ui header">
                    {video.snippet.title}
                </h4>
                <p>{video.snippet.description}</p>
            </div>
        </div>
    )
}

export default VideoDetail